import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Header1, Header2, Header3 } from '../headers';
import LinkComponent, { CTALink } from '../linkComponent';
import { HomePageEventList, HomePagePostList } from './types';
import { truncate } from '../../utils';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { ContentfulAsset, Maybe } from '../../../types/graphql-types';

import './styles/latestLinks.css';

type LatestLinkProps = {
  baseUrl: string;
  slug: string;
  photoDesktop?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
  photoMobile?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
  categoryLink?: string;
  category?: string;
  featuredBlurb?: string;
  header?: string;
};

export const LatestLink: React.FC<LatestLinkProps> = ({
  baseUrl,
  slug,
  photoDesktop,
  photoMobile,
  categoryLink,
  category,
  featuredBlurb,
  header,
}: LatestLinkProps) => (
  <div className="flex flex-wrap md:flex-nowrap mb-8 md:mb-0 mt-2 pb-2 blog-list-item">
    <div className="cursor-pointer blog-list-image">
      <div className="hidden md:block">
        {photoDesktop && (
          <LinkComponent className="pr-3" link={`/${baseUrl}/${slug}`}>
            <GatsbyImage
              alt="TODO: add alt tag"
              image={photoDesktop.gatsbyImageData}
            />
          </LinkComponent>
        )}
      </div>
      <div className="block md:hidden">
        {photoMobile && (
          <LinkComponent link={`/${baseUrl}/${slug}`}>
            <GatsbyImage
              alt="TODO: add alt tag"
              image={photoMobile.gatsbyImageData}
            />
          </LinkComponent>
        )}
      </div>
    </div>
    <div className="blog-list-text flex-initial">
      {category && categoryLink && (
        <div className="hidden md:inline-block">
          <CTALink
            link={categoryLink === 'news' ? '/blog' : `/issues/${categoryLink}`}
            text={category}
          />
        </div>
      )}
      <div className="blog-list-title mt-1 normal-case md:uppercase cursor-pointer">
        <LinkComponent link={`/${baseUrl}/${slug}`}>
          {featuredBlurb && (
            <Header2 color="black" className="mx-5 md:mx-0 hidden md:block">
              {featuredBlurb}
            </Header2>
          )}
          {!featuredBlurb && (
            <Header3 color="black" className="hidden md:block">
              {truncate(header || '', 150)}
            </Header3>
          )}
          <Header3 color="white" className="block md:hidden mx-5">
            {truncate(header || '', 150)}
          </Header3>
        </LinkComponent>
      </div>
      <div className="inline-block md:hidden mx-5 mt-2">
        <CTALink link={`/${baseUrl}/${slug}`} text="Read More" />
      </div>
    </div>
  </div>
);

LatestLink.defaultProps = {
  photoDesktop: undefined,
  photoMobile: undefined,
  categoryLink: undefined,
  category: undefined,
  featuredBlurb: undefined,
  header: undefined,
};

type HomePageLatestLinksSectionProps = {
  postList?: HomePagePostList;
  eventList?: HomePageEventList;
};

const HomePageLatestLinks: React.FC<HomePageLatestLinksSectionProps> = ({
  postList,
  eventList,
}: HomePageLatestLinksSectionProps) => {
  let name;
  let posts;
  let events;
  if (postList) {
    name = postList.name;
    posts = postList.posts;
  } else if (eventList) {
    name = eventList.name;
    events = eventList.events;
  } else {
    return null;
  }
  return (
    <div
      className={`latest-links ${
        process.env.GATSBY_DOMAIN_ID !== 'rlp' ? 'usm' : 'rlp'
      }`}
    >
      <Header1
        color="black"
        mobileColorsFlipped
        className="uppercase mx-5 md:mx-0 pt-8 md:pt-0 border-t-4 border-white md:border-t-0"
      >
        {name}
      </Header1>
      <div className="w-auto md:w-72 lg:w-84">
        {posts &&
          posts.map((post) => {
            if (!post || !post.slug) {
              return null;
            }

            return (
              <LatestLink
                key={post.id}
                baseUrl="blog"
                slug={post.slug}
                categoryLink={post.topic?.topicId || ''}
                category={post.topic?.name || ''}
                featuredBlurb={post.featuredBlurb || undefined}
                header={post.header || undefined}
                photoDesktop={post.photoDesktop}
                photoMobile={post.photoMobile}
              />
            );
          })}
        {events &&
          events.map((event) => {
            if (!event || !event.slug) {
              return null;
            }

            let categoryLink;
            let category;
            if (event.online) {
              categoryLink = '/webinars';
              category = 'Online';
            } else if (event.region) {
              categoryLink = `/events/region/${event.region.locationId}`;
              category = event.region.name || undefined;
            }

            return (
              <LatestLink
                key={event.id}
                baseUrl="events"
                slug={event.slug}
                categoryLink={categoryLink}
                category={category}
                featuredBlurb={event.featuredBlurb || undefined}
                header={event.title || undefined}
                photoDesktop={event.photoDesktop}
                photoMobile={event.photoMobile}
              />
            );
          })}
      </div>
    </div>
  );
};

HomePageLatestLinks.defaultProps = {
  postList: undefined,
  eventList: undefined,
};

export default HomePageLatestLinks;
