import React from 'react';
import { graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import {
  AuthorBySlugQuery,
  // eslint-disable-next-line import/extensions,import/no-unresolved
} from '../../types/graphql-types';
import { Header1, Header2 } from './headers';
import Layout from './layout';
import SEO from './seo';
import ContentfulRichText from './contentfulRichText';
import PostList from './postList';
import sortByPublished from '../utils/sortByPublished';
import { LatestLink } from './homepage/latestLinks';

import './homepage/styles/latestLinks.css';

type Props = {
  data: AuthorBySlugQuery;
};

const AuthorPage: React.FC<Props> = ({ data }: Props) => {
  const name = data?.contentfulAuthor?.name || 'Unknown Author';
  const photoDesktop = data?.contentfulAuthor?.photoDesktop;
  const photoMobile = data?.contentfulAuthor?.photoMobile;
  const blogPosts =
    data?.contentfulAuthor?.blog_post
      ?.map((post) => {
        if (!post) {
          return {
            id: 'none',
            header: 'none',
            slug: 'none',
            createdAt: 'none',
            createdAtTimestamp: 0,
            publishedTimestamp: 0,
          };
        }
        return post;
      })
      .filter((post) => post.id !== 'none')
      .sort(sortByPublished) || [];

  const blogPostsLatest = blogPosts.slice(0, 4);
  const blogPostsRest = blogPosts.slice(4);

  return (
    <Layout pathname="TODO: add pathname">
      <SEO title={`Uhuru Solidarity Movement: ${name}`} />
      <div className="container mx-auto pt-16">
        <div
          className={`md:flex ${
            blogPosts.length > 0 ? 'md:justify-between' : 'md:justify-around'
          }`}
        >
          <div className="max-w-screen-sm author-biography text-base mb-16 mx-auto">
            <Header1
              tag="h1"
              color="black"
              mobileColorsFlipped
              className="mx-5 md:mx-0 mb-4"
            >
              {name}
            </Header1>
            <div className="hidden md:block">
              {photoDesktop && (
                <div className="max-w-screen-sm">
                  <GatsbyImage
                    alt="TODO: add alt tag"
                    image={photoDesktop.gatsbyImageData}
                  />
                </div>
              )}
            </div>
            <div className="block md:hidden">
              {photoMobile && (
                <GatsbyImage
                  alt="TODO: add alt tag"
                  image={photoMobile.gatsbyImageData}
                />
              )}
            </div>
            <div className="author-content text-base text-white md:text-black">
              <ContentfulRichText
                document={data?.contentfulAuthor?.biography}
              />
            </div>
          </div>
          {blogPosts.length > 0 && (
            <div className="hidden md:block latest-links usm">
              <Header1
                color="black"
                mobileColorsFlipped
                className="uppercase mx-5 md:mx-0 pt-8 md:pt-0 border-t-4 border-white md:border-t-0"
              >
                Latest
              </Header1>
              <div className="w-auto md:w-72 lg:w-84">
                {blogPostsLatest.map((post) => {
                  if (!post || !post.slug) {
                    return null;
                  }

                  return (
                    <LatestLink
                      key={post.id}
                      baseUrl="blog"
                      slug={post.slug}
                      categoryLink={post.topic?.topicId || ''}
                      category={post.topic?.name || ''}
                      featuredBlurb={post.featuredBlurb || undefined}
                      header={post.header || undefined}
                      photoDesktop={post.photoDesktopLatest}
                      photoMobile={post.photoMobile}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="block md:hidden">
          <Header2 mobileColorsFlipped color="black" className="mx-5">
            Posts by {name}
          </Header2>
          {blogPosts.length > 0 && <PostList postList={blogPosts} />}
        </div>
        <div className="hidden md:block max-w-screen-lg mx-auto">
          {blogPosts.length > 4 && <PostList postList={blogPostsRest} />}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query AuthorBySlug($slug: String!) {
    contentfulAuthor(slug: { eq: $slug }) {
      name
      photoDesktop: photo {
        gatsbyImageData(layout: CONSTRAINED, height: 400, width: 750)
      }
      photoMobile: photo {
        gatsbyImageData(
          layout: CONSTRAINED
          aspectRatio: 1.75
          width: 700
          cropFocus: CENTER
          placeholder: BLURRED
        )
      }
      biography {
        raw
        references {
          ... on ContentfulVideo {
            contentful_id
            __typename
            embedUrl
            thumbnail {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      blog_post {
        id
        header
        slug
        photoDesktop: photo {
          gatsbyImageData(layout: CONSTRAINED, height: 400, width: 750)
        }
        photoDesktopLatest: photo {
          gatsbyImageData(
            layout: CONSTRAINED
            aspectRatio: 1
            height: 195
            width: 195
            cropFocus: CENTER
          )
        }
        photoMobile: photo {
          gatsbyImageData(
            layout: CONSTRAINED
            aspectRatio: 1.75
            width: 1280
            cropFocus: CENTER
            placeholder: BLURRED
          )
        }
        topic {
          topicId
          name
        }
        featuredBlurb
        author {
          name
        }
        published(formatString: "MMMM Do, YYYY")
        createdAt(formatString: "MMMM Do, YYYY")
        publishedTimestamp: published(formatString: "X")
        createdAtTimestamp: createdAt(formatString: "X")
      }
    }
  }
`;

export default AuthorPage;
